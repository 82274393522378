export type ColorType = {
  hex: string;
};
export type TertiaryTagType = {
  text: string;
  fontColor: ColorType;
  backgroundColor: ColorType;
};
export type TertiaryTagProps = {
  tag: TertiaryTagType;
  fullWidth?: boolean;
  pdp?: boolean;
};

export const TertiaryTag: React.FC<TertiaryTagProps> = ({
  tag: { text, fontColor, backgroundColor },
  fullWidth = false,
  pdp = false,
}) => {
  const bgColor = backgroundColor?.hex || '#848484';
  const textColor = fontColor?.hex || '#FFFFFF';
  const width = fullWidth ? 'w-full' : 'w-1/2';
  const height = pdp ? 'h-8 md:h-12' : 'h-6';
  const textSize = pdp ? 'text-sm lg:text-xl' : 'text-xxs lg:text-sm';
  const position = pdp ? '' : 'absolute';

  return (
    <div
      className={`${width} ${height} ${position} bottom-0 flex  items-center justify-center px-2 py-0.5`}
      style={{ backgroundColor: bgColor }}
    >
      <span
        className={`whitespace-nowrap font-interBold ${textSize}`}
        style={{ color: textColor }}
      >
        {text}
      </span>
    </div>
  );
};
